// Generated by purs version 0.15.8
import * as $foreign from "./foreign.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Unsafe_Coerce from "../Unsafe.Coerce/index.js";
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var writeStateWithCallback = $foreign.setStateWithCallbackImpl;
var writeState = $foreign.setStateImpl;
var unsafeCreateLeafElement = $foreign.createLeafElementImpl;
var unsafeCreateElementDynamic = $foreign.createElementDynamicImpl;
var unsafeCreateElement = $foreign.createElementImpl;
var toElement = function (dict) {
    return dict.toElement;
};
var setStateWithCallback = function () {
    return $foreign.setStateWithCallbackImpl;
};
var setState = function () {
    return $foreign.setStateImpl;
};
var reactPureComponentSpec = function () {
    return function () {
        return {};
    };
};
var reactPropFields = function () {
    return function () {
        return {};
    };
};
var reactComponentSpec = function () {
    return function () {
        return {};
    };
};
var pureComponentWithDerivedState = function () {
    return $foreign.componentWithDerivedStateImpl;
};
var pureComponent = function () {
    return $foreign.pureComponentImpl;
};
var modifyStateWithCallback = $foreign.setStateWithCallbackImpl;
var modifyState = $foreign.setStateImpl;
var isReactElementString = {
    toElement: Unsafe_Coerce.unsafeCoerce
};
var isReactElementReactElement = {
    toElement: /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn)
};
var isReactElementNumber = {
    toElement: Unsafe_Coerce.unsafeCoerce
};
var isReactElementInt = {
    toElement: Unsafe_Coerce.unsafeCoerce
};
var isReactElementChildren = {
    toElement: Unsafe_Coerce.unsafeCoerce
};
var forceUpdate = function ($$this) {
    return $foreign.forceUpdateWithCallback($$this)(pure(Data_Unit.unit));
};
var createLeafElement = function () {
    return $foreign.createLeafElementImpl;
};
var createElementDynamic = function () {
    return $foreign.createElementDynamicImpl;
};
var createElement = function () {
    return $foreign.createElementImpl;
};
var createElement1 = /* #__PURE__ */ createElement();
var fragmentWithKey = /* #__PURE__ */ (function () {
    var $10 = createElement1($foreign.fragment);
    return function ($11) {
        return $10((function (v) {
            return {
                key: v
            };
        })($11));
    };
})();
var semigroupReactElement = {
    append: function (a) {
        return function (b) {
            if ($foreign.isEmptyReactElement(a)) {
                return b;
            };
            if ($foreign.isEmptyReactElement(b)) {
                return a;
            };
            if (Data_Boolean.otherwise) {
                return toElement(isReactElementArray)([ a, b ]);
            };
            throw new Error("Failed pattern match at React (line 88, column 1 - line 92, column 37): " + [ a.constructor.name, b.constructor.name ]);
        };
    }
};
var monoidReactElement = {
    mempty: $foreign.emptyReactElement,
    Semigroup0: function () {
        return semigroupReactElement;
    }
};
var isReactElementArray = {
    toElement: function (v) {
        if (v.length === 0) {
            return Data_Monoid.mempty(monoidReactElement);
        };
        return createElement1($foreign.fragment)({})(v);
    }
};
var componentWithDerivedState = function () {
    return $foreign.componentWithDerivedStateImpl;
};
var component = function () {
    return $foreign.componentImpl;
};
export {
    statelessComponent,
    getProps,
    getState,
    forceUpdateWithCallback,
    createElementTagName,
    createElementTagNameDynamic,
    childrenToArray,
    childrenCount,
    fragment,
    createContext
} from "./foreign.js";
export {
    component,
    componentWithDerivedState,
    pureComponent,
    pureComponentWithDerivedState,
    setState,
    setStateWithCallback,
    writeState,
    writeStateWithCallback,
    modifyState,
    modifyStateWithCallback,
    forceUpdate,
    createElement,
    unsafeCreateElement,
    createElementDynamic,
    unsafeCreateElementDynamic,
    createLeafElement,
    unsafeCreateLeafElement,
    toElement,
    fragmentWithKey,
    semigroupReactElement,
    monoidReactElement,
    reactComponentSpec,
    reactPureComponentSpec,
    reactPropFields,
    isReactElementString,
    isReactElementNumber,
    isReactElementInt,
    isReactElementChildren,
    isReactElementReactElement,
    isReactElementArray
};
