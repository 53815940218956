// Generated by purs version 0.15.8
import * as $foreign from "./foreign.js";
var crashWith = function () {
    return $foreign["_crashWith"];
};
var crashWith1 = /* #__PURE__ */ crashWith();
var crash = function () {
    return crashWith1("Partial.crash: partial function");
};
export {
    crash,
    crashWith
};
