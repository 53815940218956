// Generated by purs version 0.15.8
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Concur_React_DOM from "../Concur.React.DOM/index.js";
import * as Concur_React_Props from "../Concur.React.Props/index.js";
import * as Concur_React_Run from "../Concur.React.Run/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
var widgetMultiAlternative = /* #__PURE__ */ Concur_Core_Types.widgetMultiAlternative(Data_Monoid.monoidArray);
var div = /* #__PURE__ */ Concur_React_DOM.div(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var span = /* #__PURE__ */ Concur_React_DOM.span(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var a = /* #__PURE__ */ Concur_React_DOM.a(widgetMultiAlternative)(Concur_Core_Types.widgetShiftMap);
var text = /* #__PURE__ */ Concur_React_DOM.text(Concur_Core_LiftWidget.widgetLiftWidget);
var navbar = /* #__PURE__ */ div([ /* #__PURE__ */ Concur_React_Props["_id"]("navbar") ])([ /* #__PURE__ */ div([ /* #__PURE__ */ Concur_React_Props["_id"]("logo") ])([ /* #__PURE__ */ Concur_React_DOM.img(Concur_Core_LiftWidget.widgetLiftWidget)([ /* #__PURE__ */ Concur_React_Props.height("75"), /* #__PURE__ */ Concur_React_Props.src("img/labs_logo.png") ]) ]), /* #__PURE__ */ div([ /* #__PURE__ */ Concur_React_Props["_id"]("links") ])([ /* #__PURE__ */ span([ /* #__PURE__ */ Concur_React_Props.className("link") ])([ /* #__PURE__ */ a([  ])([ /* #__PURE__ */ text("about") ]) ]), /* #__PURE__ */ span([ /* #__PURE__ */ Concur_React_Props.className("link") ])([ /* #__PURE__ */ a([  ])([ /* #__PURE__ */ text("team") ]) ]), /* #__PURE__ */ span([ /* #__PURE__ */ Concur_React_Props.className("link") ])([ /* #__PURE__ */ a([  ])([ /* #__PURE__ */ text("contact") ]) ]) ]) ]);
var footer = /* #__PURE__ */ div([ /* #__PURE__ */ Concur_React_Props["_id"]("footer") ])([ /* #__PURE__ */ Concur_React_DOM.hr(Concur_Core_LiftWidget.widgetLiftWidget)([  ]), /* #__PURE__ */ text("footer here") ]);
var about = /* #__PURE__ */ div([ /* #__PURE__ */ Concur_React_Props["_id"]("main") ])([ /* #__PURE__ */ div([ /* #__PURE__ */ Concur_React_Props.className("header") ])([ /* #__PURE__ */ text("Coming soon...") ]) ]);
var kernel = /* #__PURE__ */ div([ /* #__PURE__ */ Concur_React_Props["_id"]("gutters") ])([ navbar, about, footer ]);
var main = /* #__PURE__ */ Concur_React_Run.runWidgetInDom("root")(kernel);
export {
    about,
    navbar,
    footer,
    kernel,
    main
};
