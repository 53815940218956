// Generated by purs version 0.15.8
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
var widgetIsWidget = function (dictMonoid) {
    var widgetMultiAlternative = Concur_Core_Types.widgetMultiAlternative(dictMonoid);
    return {
        Monad0: function () {
            return Concur_Core_Types.widgetMonad;
        },
        Monoid1: function () {
            return dictMonoid;
        },
        ShiftMap2: function () {
            return Concur_Core_Types.widgetShiftMap;
        },
        LiftWidget3: function () {
            return Concur_Core_LiftWidget.widgetLiftWidget;
        },
        MultiAlternative4: function () {
            return widgetMultiAlternative;
        }
    };
};
export {
    widgetIsWidget
};
