// Generated by purs version 0.15.8
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Monad_RWS_Trans from "../Control.Monad.RWS.Trans/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var writerShiftMap = function (dictMonoid) {
    var mempty = Data_Monoid.mempty(dictMonoid);
    return {
        shiftMap: function (f) {
            return function (v) {
                return f(function (a) {
                    return new Data_Tuple.Tuple(a, mempty);
                })(v);
            };
        }
    };
};
var stateShiftMap = function (dictMonad) {
    return {
        shiftMap: function (f) {
            return function (v) {
                return function (s) {
                    return f(function (a) {
                        return new Data_Tuple.Tuple(a, s);
                    })(v(s));
                };
            };
        }
    };
};
var shiftMap = function (dict) {
    return dict.shiftMap;
};
var rwsShiftMap = function (dictMonoid) {
    var mempty = Data_Monoid.mempty(dictMonoid);
    return {
        shiftMap: function (f) {
            return function (v) {
                return function (r) {
                    return function (s) {
                        return f(function (a) {
                            return new Control_Monad_RWS_Trans.RWSResult(s, a, mempty);
                        })(v(r)(s));
                    };
                };
            };
        }
    };
};
var readerShiftMap = {
    shiftMap: function (f) {
        return function (v) {
            return function (r) {
                return f(identity)(v(r));
            };
        };
    }
};
var exceptShiftMap = {
    shiftMap: function (f) {
        return function (v) {
            return f(Data_Either.Right.create)(v);
        };
    }
};
export {
    shiftMap,
    exceptShiftMap,
    rwsShiftMap,
    readerShiftMap,
    stateShiftMap,
    writerShiftMap
};
