// Generated by purs version 0.15.8
import * as Concur_Core_IsWidget from "../Concur.Core.IsWidget/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Monad_Free from "../Control.Monad.Free/index.js";
import * as Control_Parallel_Class from "../Control.Parallel.Class/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_AVar from "../Effect.AVar/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_AVar from "../Effect.Aff.AVar/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
var resume = /* #__PURE__ */ Control_Monad_Free.resume(Concur_Core_Types.functorWidgetStep);
var pure = /* #__PURE__ */ Control_Applicative.pure(Control_Monad_Free.freeApplicative);
var $$void = /* #__PURE__ */ Data_Functor["void"](Effect.functorEffect);
var sequential = /* #__PURE__ */ Control_Parallel_Class.sequential(Effect_Aff.parallelAff);
var alt = /* #__PURE__ */ Control_Alt.alt(Effect_Aff.altParAff);
var parallel = /* #__PURE__ */ Control_Parallel_Class.parallel(Effect_Aff.parallelAff);
var liftAff = /* #__PURE__ */ Effect_Aff_Class.liftAff(Effect_Aff_Class.monadAffAff);
var map = /* #__PURE__ */ Data_Functor.map(Effect_Aff.functorAff);
var mkNodeWidget$prime = function (mkView) {
    return function (w) {
        var v = resume(w);
        if (v instanceof Data_Either.Right) {
            return pure(v.value0);
        };
        if (v instanceof Data_Either.Left && v.value0 instanceof Concur_Core_Types.WidgetStepEff) {
            return Control_Monad_Free.wrap(new Concur_Core_Types.WidgetStepEff(function __do() {
                var w$prime = v.value0.value0();
                return mkNodeWidget$prime(mkView)(w$prime);
            }));
        };
        if (v instanceof Data_Either.Left && v.value0 instanceof Concur_Core_Types.WidgetStepView) {
            return Control_Monad_Free.wrap(new Concur_Core_Types.WidgetStepEff(function __do() {
                var $$var = Effect_AVar.empty();
                var eventHandler = function (a) {
                    return $$void(Effect_AVar.tryPut(pure(a))($$var));
                };
                var cont$prime = sequential(alt(parallel(liftAff(Effect_Aff_AVar.take($$var))))(parallel(map(mkNodeWidget$prime(mkView))(v.value0.value0.cont))));
                return Control_Monad_Free.wrap(new Concur_Core_Types.WidgetStepView({
                    view: mkView(eventHandler)(v.value0.value0.view),
                    cont: cont$prime
                }));
            }));
        };
        throw new Error("Failed pattern match at Concur.Core (line 34, column 26 - line 48, column 10): " + [ v.constructor.name ]);
    };
};
var mkNodeWidget = function (mkView) {
    return function (v) {
        return mkNodeWidget$prime(mkView)(v);
    };
};
var mkLeafWidget = function (mkView) {
    return Control_Monad_Free.wrap(new Concur_Core_Types.WidgetStepEff(function __do() {
        var $$var = Effect_AVar.empty();
        return Control_Monad_Free.wrap(new Concur_Core_Types.WidgetStepView({
            view: mkView(function (a) {
                return $$void(Effect_AVar.tryPut(pure(a))($$var));
            }),
            cont: liftAff(Effect_Aff_AVar.take($$var))
        }));
    }));
};
export {
    mkLeafWidget,
    mkNodeWidget,
    mkNodeWidget$prime
};
export {
    liftWidget
} from "../Concur.Core.LiftWidget/index.js";
export {
    Widget,
    WidgetStepEff,
    WidgetStepView,
    unWidget
} from "../Concur.Core.Types/index.js";
