// Generated by purs version 0.15.8
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad from "../Control.Monad/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Identity from "../Data.Identity/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Ref from "../Effect.Ref/index.js";
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(Effect.bindEffect);
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var Loop = /* #__PURE__ */ (function () {
    function Loop(value0) {
        this.value0 = value0;
    };
    Loop.create = function (value0) {
        return new Loop(value0);
    };
    return Loop;
})();
var Done = /* #__PURE__ */ (function () {
    function Done(value0) {
        this.value0 = value0;
    };
    Done.create = function (value0) {
        return new Done(value0);
    };
    return Done;
})();
var tailRecM = function (dict) {
    return dict.tailRecM;
};
var tailRecM2 = function (dictMonadRec) {
    var tailRecM1 = tailRecM(dictMonadRec);
    return function (f) {
        return function (a) {
            return function (b) {
                return tailRecM1(function (o) {
                    return f(o.a)(o.b);
                })({
                    a: a,
                    b: b
                });
            };
        };
    };
};
var tailRecM3 = function (dictMonadRec) {
    var tailRecM1 = tailRecM(dictMonadRec);
    return function (f) {
        return function (a) {
            return function (b) {
                return function (c) {
                    return tailRecM1(function (o) {
                        return f(o.a)(o.b)(o.c);
                    })({
                        a: a,
                        b: b,
                        c: c
                    });
                };
            };
        };
    };
};
var untilJust = function (dictMonadRec) {
    var tailRecM1 = tailRecM(dictMonadRec);
    var mapFlipped = Data_Functor.mapFlipped((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0());
    return function (m) {
        return tailRecM1(function (v) {
            return mapFlipped(m)(function (v1) {
                if (v1 instanceof Data_Maybe.Nothing) {
                    return new Loop(Data_Unit.unit);
                };
                if (v1 instanceof Data_Maybe.Just) {
                    return new Done(v1.value0);
                };
                throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 179, column 43 - line 181, column 19): " + [ v1.constructor.name ]);
            });
        })(Data_Unit.unit);
    };
};
var whileJust = function (dictMonoid) {
    var append = Data_Semigroup.append(dictMonoid.Semigroup0());
    var mempty = Data_Monoid.mempty(dictMonoid);
    return function (dictMonadRec) {
        var tailRecM1 = tailRecM(dictMonadRec);
        var mapFlipped = Data_Functor.mapFlipped((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0());
        return function (m) {
            return tailRecM1(function (v) {
                return mapFlipped(m)(function (v1) {
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return new Done(v);
                    };
                    if (v1 instanceof Data_Maybe.Just) {
                        return new Loop(append(v)(v1.value0));
                    };
                    throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 172, column 45 - line 174, column 26): " + [ v1.constructor.name ]);
                });
            })(mempty);
        };
    };
};
var tailRec = function (f) {
    var go = function ($copy_v) {
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(v) {
            if (v instanceof Loop) {
                $copy_v = f(v.value0);
                return;
            };
            if (v instanceof Done) {
                $tco_done = true;
                return v.value0;
            };
            throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 103, column 3 - line 103, column 25): " + [ v.constructor.name ]);
        };
        while (!$tco_done) {
            $tco_result = $tco_loop($copy_v);
        };
        return $tco_result;
    };
    return function ($85) {
        return go(f($85));
    };
};
var tailRec2 = function (f) {
    return function (a) {
        return function (b) {
            return tailRec(function (o) {
                return f(o.a)(o.b);
            })({
                a: a,
                b: b
            });
        };
    };
};
var tailRec3 = function (f) {
    return function (a) {
        return function (b) {
            return function (c) {
                return tailRec(function (o) {
                    return f(o.a)(o.b)(o.c);
                })({
                    a: a,
                    b: b,
                    c: c
                });
            };
        };
    };
};
var monadRecMaybe = {
    tailRecM: function (f) {
        return function (a0) {
            var g = function (v) {
                if (v instanceof Data_Maybe.Nothing) {
                    return new Done(Data_Maybe.Nothing.value);
                };
                if (v instanceof Data_Maybe.Just && v.value0 instanceof Loop) {
                    return new Loop(f(v.value0.value0));
                };
                if (v instanceof Data_Maybe.Just && v.value0 instanceof Done) {
                    return new Done(new Data_Maybe.Just(v.value0.value0));
                };
                throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 153, column 7 - line 153, column 31): " + [ v.constructor.name ]);
            };
            return tailRec(g)(f(a0));
        };
    },
    Monad0: function () {
        return Data_Maybe.monadMaybe;
    }
};
var monadRecIdentity = {
    tailRecM: function (f) {
        var runIdentity = function (v) {
            return v;
        };
        var $86 = tailRec(function ($88) {
            return runIdentity(f($88));
        });
        return function ($87) {
            return Data_Identity.Identity($86($87));
        };
    },
    Monad0: function () {
        return Data_Identity.monadIdentity;
    }
};
var monadRecFunction = {
    tailRecM: function (f) {
        return function (a0) {
            return function (e) {
                return tailRec(function (a) {
                    return f(a)(e);
                })(a0);
            };
        };
    },
    Monad0: function () {
        return Control_Monad.monadFn;
    }
};
var monadRecEither = {
    tailRecM: function (f) {
        return function (a0) {
            var g = function (v) {
                if (v instanceof Data_Either.Left) {
                    return new Done(new Data_Either.Left(v.value0));
                };
                if (v instanceof Data_Either.Right && v.value0 instanceof Loop) {
                    return new Loop(f(v.value0.value0));
                };
                if (v instanceof Data_Either.Right && v.value0 instanceof Done) {
                    return new Done(new Data_Either.Right(v.value0.value0));
                };
                throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 145, column 7 - line 145, column 33): " + [ v.constructor.name ]);
            };
            return tailRec(g)(f(a0));
        };
    },
    Monad0: function () {
        return Data_Either.monadEither;
    }
};
var monadRecEffect = {
    tailRecM: function (f) {
        return function (a) {
            var fromDone = function (v) {
                if (v instanceof Done) {
                    return v.value0;
                };
                throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 137, column 30 - line 137, column 44): " + [ v.constructor.name ]);
            };
            return function __do() {
                var r = bindFlipped(Effect_Ref["new"])(f(a))();
                (function () {
                    while (!(function __do() {
                        var v = Effect_Ref.read(r)();
                        if (v instanceof Loop) {
                            var e = f(v.value0)();
                            Effect_Ref.write(e)(r)();
                            return false;
                        };
                        if (v instanceof Done) {
                            return true;
                        };
                        throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 128, column 22 - line 133, column 28): " + [ v.constructor.name ]);
                    })()) {

                    };
                    return {};
                })();
                return map(fromDone)(Effect_Ref.read(r))();
            };
        };
    },
    Monad0: function () {
        return Effect.monadEffect;
    }
};
var loop3 = function (a) {
    return function (b) {
        return function (c) {
            return new Loop({
                a: a,
                b: b,
                c: c
            });
        };
    };
};
var loop2 = function (a) {
    return function (b) {
        return new Loop({
            a: a,
            b: b
        });
    };
};
var functorStep = {
    map: function (f) {
        return function (m) {
            if (m instanceof Loop) {
                return new Loop(m.value0);
            };
            if (m instanceof Done) {
                return new Done(f(m.value0));
            };
            throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 0, column 0 - line 0, column 0): " + [ m.constructor.name ]);
        };
    }
};
var forever = function (dictMonadRec) {
    var tailRecM1 = tailRecM(dictMonadRec);
    var voidRight = Data_Functor.voidRight((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0());
    return function (ma) {
        return tailRecM1(function (u) {
            return voidRight(new Loop(u))(ma);
        })(Data_Unit.unit);
    };
};
var bifunctorStep = {
    bimap: function (v) {
        return function (v1) {
            return function (v2) {
                if (v2 instanceof Loop) {
                    return new Loop(v(v2.value0));
                };
                if (v2 instanceof Done) {
                    return new Done(v1(v2.value0));
                };
                throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 33, column 1 - line 35, column 34): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
            };
        };
    }
};
export {
    Loop,
    Done,
    tailRec,
    tailRec2,
    tailRec3,
    tailRecM,
    tailRecM2,
    tailRecM3,
    forever,
    whileJust,
    untilJust,
    loop2,
    loop3,
    functorStep,
    bifunctorStep,
    monadRecIdentity,
    monadRecEffect,
    monadRecFunction,
    monadRecEither,
    monadRecMaybe
};
