// Generated by purs version 0.15.8
import * as Concur_Core_Discharge from "../Concur.Core.Discharge/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Effect_Exception from "../Effect.Exception/index.js";
import * as React from "../React/index.js";
var toElement = /* #__PURE__ */ React.toElement(React.isReactElementArray);
var discharge = /* #__PURE__ */ Concur_Core_Discharge.discharge(Data_Monoid.monoidArray);
var $$void = /* #__PURE__ */ Data_Functor["void"](Effect.functorEffect);
var show = /* #__PURE__ */ Data_Show.show(Effect_Exception.showError);
var dischargePartialEffect = /* #__PURE__ */ Concur_Core_Discharge.dischargePartialEffect(Data_Monoid.monoidArray);
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Effect.applyEffect);
var component = /* #__PURE__ */ React.component();
var createLeafElement = /* #__PURE__ */ React.createLeafElement();
var mkComponentState = function (v) {
    return {
        view: v
    };
};
var componentClassWithMount = function (onMount) {
    return function (winit) {
        var render = function (st) {
            return toElement(st.view);
        };
        var handler = function (v) {
            return function (v1) {
                if (v1 instanceof Data_Either.Right) {
                    return function __do() {
                        var v2 = discharge(handler(v))(v1.value0)();
                        return $$void(React.writeState(v)(mkComponentState(v2)))();
                    };
                };
                if (v1 instanceof Data_Either.Left) {
                    return function __do() {
                        Effect_Console.log("FAILED! " + show(v1.value0))();
                        return Data_Unit.unit;
                    };
                };
                throw new Error("Failed pattern match at Concur.React (line 32, column 5 - line 34, column 52): " + [ v.constructor.name, v1.constructor.name ]);
            };
        };
        var component1 = function ($$this) {
            return function __do() {
                var v = dischargePartialEffect(winit)();
                return {
                    state: mkComponentState(v.value1),
                    render: map(render)(React.getState($$this)),
                    componentDidMount: applySecond(onMount)(handler($$this)(new Data_Either.Right(v.value0)))
                };
            };
        };
        return component("Concur")(component1);
    };
};
var componentClass = /* #__PURE__ */ componentClassWithMount(/* #__PURE__ */ Data_Monoid.mempty(/* #__PURE__ */ Effect.monoidEffect(Data_Monoid.monoidUnit)));
var renderComponent = function (init) {
    return createLeafElement(componentClass(init))({});
};
export {
    mkComponentState,
    componentClassWithMount,
    componentClass,
    renderComponent
};
