// Generated by purs version 0.15.8
import * as Concur_Core_DOM from "../Concur.Core.DOM/index.js";
import * as Concur_Core_LiftWidget from "../Concur.Core.LiftWidget/index.js";
import * as Concur_Core_Types from "../Concur.Core.Types/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as React_DOM from "../React.DOM/index.js";
var viewAdapter = function (f) {
    return function (ps) {
        return function (vs) {
            return [ f(ps)(vs) ];
        };
    };
};
var text = function (dictLiftWidget) {
    var liftWidget = Concur_Core_LiftWidget.liftWidget(dictLiftWidget);
    return function (str) {
        return liftWidget(Concur_Core_Types.display([ React_DOM.text(str) ]));
    };
};
var number = function (dictLiftWidget) {
    var liftWidget = Concur_Core_LiftWidget.liftWidget(dictLiftWidget);
    return function (x) {
        return liftWidget(Concur_Core_Types.display([ React_DOM.number(x) ]));
    };
};
var $$int = function (dictLiftWidget) {
    var liftWidget = Concur_Core_LiftWidget.liftWidget(dictLiftWidget);
    return function (x) {
        return liftWidget(Concur_Core_Types.display([ React_DOM["int"](x) ]));
    };
};
var elLeaf = function (dictLiftWidget) {
    var elLeaf1 = Concur_Core_DOM.elLeaf(dictLiftWidget)(Data_Functor.functorArray);
    return function (f) {
        return elLeaf1(function (ps) {
            return [ f(ps) ];
        });
    };
};
var embed = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.embed);
};
var embed$prime = function (dictLiftWidget) {
    return embed(dictLiftWidget)([  ]);
};
var hr = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.hr);
};
var hr$prime = function (dictLiftWidget) {
    return hr(dictLiftWidget)([  ]);
};
var img = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.img);
};
var img$prime = function (dictLiftWidget) {
    return img(dictLiftWidget)([  ]);
};
var input = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.input);
};
var input$prime = function (dictLiftWidget) {
    return input(dictLiftWidget)([  ]);
};
var keygen = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.keygen);
};
var keygen$prime = function (dictLiftWidget) {
    return keygen(dictLiftWidget)([  ]);
};
var link = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.link);
};
var link$prime = function (dictLiftWidget) {
    return link(dictLiftWidget)([  ]);
};
var menuitem = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.menuitem);
};
var menuitem$prime = function (dictLiftWidget) {
    return menuitem(dictLiftWidget)([  ]);
};
var meta = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.meta);
};
var meta$prime = function (dictLiftWidget) {
    return meta(dictLiftWidget)([  ]);
};
var param = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.param);
};
var param$prime = function (dictLiftWidget) {
    return param(dictLiftWidget)([  ]);
};
var source = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.source);
};
var source$prime = function (dictLiftWidget) {
    return source(dictLiftWidget)([  ]);
};
var track = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.track);
};
var track$prime = function (dictLiftWidget) {
    return track(dictLiftWidget)([  ]);
};
var wbr = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.wbr);
};
var wbr$prime = function (dictLiftWidget) {
    return wbr(dictLiftWidget)([  ]);
};
var el$prime = function (dictShiftMap) {
    var el$prime1 = Concur_Core_DOM["el$prime"](dictShiftMap);
    return function (dictMultiAlternative) {
        var el$prime2 = el$prime1(dictMultiAlternative)(Data_Functor.functorArray);
        return function (f) {
            return el$prime2(viewAdapter(f));
        };
    };
};
var em = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.em);
    };
};
var em$prime = function (dictMultiAlternative) {
    var em1 = em(dictMultiAlternative);
    return function (dictShiftMap) {
        return em1(dictShiftMap)([  ]);
    };
};
var fieldset = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.fieldset);
    };
};
var fieldset$prime = function (dictMultiAlternative) {
    var fieldset1 = fieldset(dictMultiAlternative);
    return function (dictShiftMap) {
        return fieldset1(dictShiftMap)([  ]);
    };
};
var figcaption = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.figcaption);
    };
};
var figcaption$prime = function (dictMultiAlternative) {
    var figcaption1 = figcaption(dictMultiAlternative);
    return function (dictShiftMap) {
        return figcaption1(dictShiftMap)([  ]);
    };
};
var figure = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.figure);
    };
};
var figure$prime = function (dictMultiAlternative) {
    var figure1 = figure(dictMultiAlternative);
    return function (dictShiftMap) {
        return figure1(dictShiftMap)([  ]);
    };
};
var footer = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.footer);
    };
};
var footer$prime = function (dictMultiAlternative) {
    var footer1 = footer(dictMultiAlternative);
    return function (dictShiftMap) {
        return footer1(dictShiftMap)([  ]);
    };
};
var form = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.form);
    };
};
var form$prime = function (dictMultiAlternative) {
    var form1 = form(dictMultiAlternative);
    return function (dictShiftMap) {
        return form1(dictShiftMap)([  ]);
    };
};
var h1 = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.h1);
    };
};
var h1$prime = function (dictMultiAlternative) {
    var h11 = h1(dictMultiAlternative);
    return function (dictShiftMap) {
        return h11(dictShiftMap)([  ]);
    };
};
var h2 = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.h2);
    };
};
var h2$prime = function (dictMultiAlternative) {
    var h21 = h2(dictMultiAlternative);
    return function (dictShiftMap) {
        return h21(dictShiftMap)([  ]);
    };
};
var h3 = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.h3);
    };
};
var h3$prime = function (dictMultiAlternative) {
    var h31 = h3(dictMultiAlternative);
    return function (dictShiftMap) {
        return h31(dictShiftMap)([  ]);
    };
};
var h4 = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.h4);
    };
};
var h4$prime = function (dictMultiAlternative) {
    var h41 = h4(dictMultiAlternative);
    return function (dictShiftMap) {
        return h41(dictShiftMap)([  ]);
    };
};
var h5 = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.h5);
    };
};
var h5$prime = function (dictMultiAlternative) {
    var h51 = h5(dictMultiAlternative);
    return function (dictShiftMap) {
        return h51(dictShiftMap)([  ]);
    };
};
var h6 = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.h6);
    };
};
var h6$prime = function (dictMultiAlternative) {
    var h61 = h6(dictMultiAlternative);
    return function (dictShiftMap) {
        return h61(dictShiftMap)([  ]);
    };
};
var head = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.head);
    };
};
var head$prime = function (dictMultiAlternative) {
    var head1 = head(dictMultiAlternative);
    return function (dictShiftMap) {
        return head1(dictShiftMap)([  ]);
    };
};
var header = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.header);
    };
};
var header$prime = function (dictMultiAlternative) {
    var header1 = header(dictMultiAlternative);
    return function (dictShiftMap) {
        return header1(dictShiftMap)([  ]);
    };
};
var html = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.html);
    };
};
var html$prime = function (dictMultiAlternative) {
    var html1 = html(dictMultiAlternative);
    return function (dictShiftMap) {
        return html1(dictShiftMap)([  ]);
    };
};
var i = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.i);
    };
};
var i$prime = function (dictMultiAlternative) {
    var i1 = i(dictMultiAlternative);
    return function (dictShiftMap) {
        return i1(dictShiftMap)([  ]);
    };
};
var iframe = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.iframe);
    };
};
var iframe$prime = function (dictMultiAlternative) {
    var iframe1 = iframe(dictMultiAlternative);
    return function (dictShiftMap) {
        return iframe1(dictShiftMap)([  ]);
    };
};
var ins = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.ins);
    };
};
var ins$prime = function (dictMultiAlternative) {
    var ins1 = ins(dictMultiAlternative);
    return function (dictShiftMap) {
        return ins1(dictShiftMap)([  ]);
    };
};
var kbd = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.kbd);
    };
};
var kbd$prime = function (dictMultiAlternative) {
    var kbd1 = kbd(dictMultiAlternative);
    return function (dictShiftMap) {
        return kbd1(dictShiftMap)([  ]);
    };
};
var label = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.label);
    };
};
var label$prime = function (dictMultiAlternative) {
    var label1 = label(dictMultiAlternative);
    return function (dictShiftMap) {
        return label1(dictShiftMap)([  ]);
    };
};
var legend = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.legend);
    };
};
var legend$prime = function (dictMultiAlternative) {
    var legend1 = legend(dictMultiAlternative);
    return function (dictShiftMap) {
        return legend1(dictShiftMap)([  ]);
    };
};
var li = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.li);
    };
};
var li$prime = function (dictMultiAlternative) {
    var li1 = li(dictMultiAlternative);
    return function (dictShiftMap) {
        return li1(dictShiftMap)([  ]);
    };
};
var main = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.main);
    };
};
var main$prime = function (dictMultiAlternative) {
    var main1 = main(dictMultiAlternative);
    return function (dictShiftMap) {
        return main1(dictShiftMap)([  ]);
    };
};
var mark = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.mark);
    };
};
var mark$prime = function (dictMultiAlternative) {
    var mark1 = mark(dictMultiAlternative);
    return function (dictShiftMap) {
        return mark1(dictShiftMap)([  ]);
    };
};
var menu = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.menu);
    };
};
var menu$prime = function (dictMultiAlternative) {
    var menu1 = menu(dictMultiAlternative);
    return function (dictShiftMap) {
        return menu1(dictShiftMap)([  ]);
    };
};
var meter = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.meter);
    };
};
var meter$prime = function (dictMultiAlternative) {
    var meter1 = meter(dictMultiAlternative);
    return function (dictShiftMap) {
        return meter1(dictShiftMap)([  ]);
    };
};
var nav = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.nav);
    };
};
var nav$prime = function (dictMultiAlternative) {
    var nav1 = nav(dictMultiAlternative);
    return function (dictShiftMap) {
        return nav1(dictShiftMap)([  ]);
    };
};
var noscript = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.noscript);
    };
};
var noscript$prime = function (dictMultiAlternative) {
    var noscript1 = noscript(dictMultiAlternative);
    return function (dictShiftMap) {
        return noscript1(dictShiftMap)([  ]);
    };
};
var object = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.object);
    };
};
var object$prime = function (dictMultiAlternative) {
    var object1 = object(dictMultiAlternative);
    return function (dictShiftMap) {
        return object1(dictShiftMap)([  ]);
    };
};
var ol = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.ol);
    };
};
var ol$prime = function (dictMultiAlternative) {
    var ol1 = ol(dictMultiAlternative);
    return function (dictShiftMap) {
        return ol1(dictShiftMap)([  ]);
    };
};
var optgroup = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.optgroup);
    };
};
var optgroup$prime = function (dictMultiAlternative) {
    var optgroup1 = optgroup(dictMultiAlternative);
    return function (dictShiftMap) {
        return optgroup1(dictShiftMap)([  ]);
    };
};
var option = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.option);
    };
};
var option$prime = function (dictMultiAlternative) {
    var option1 = option(dictMultiAlternative);
    return function (dictShiftMap) {
        return option1(dictShiftMap)([  ]);
    };
};
var output = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.output);
    };
};
var output$prime = function (dictMultiAlternative) {
    var output1 = output(dictMultiAlternative);
    return function (dictShiftMap) {
        return output1(dictShiftMap)([  ]);
    };
};
var p = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.p);
    };
};
var p$prime = function (dictMultiAlternative) {
    var p1 = p(dictMultiAlternative);
    return function (dictShiftMap) {
        return p1(dictShiftMap)([  ]);
    };
};
var picture = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.picture);
    };
};
var picture$prime = function (dictMultiAlternative) {
    var picture1 = picture(dictMultiAlternative);
    return function (dictShiftMap) {
        return picture1(dictShiftMap)([  ]);
    };
};
var pre = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.pre);
    };
};
var pre$prime = function (dictMultiAlternative) {
    var pre1 = pre(dictMultiAlternative);
    return function (dictShiftMap) {
        return pre1(dictShiftMap)([  ]);
    };
};
var progress = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.progress);
    };
};
var progress$prime = function (dictMultiAlternative) {
    var progress1 = progress(dictMultiAlternative);
    return function (dictShiftMap) {
        return progress1(dictShiftMap)([  ]);
    };
};
var q = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.q);
    };
};
var q$prime = function (dictMultiAlternative) {
    var q1 = q(dictMultiAlternative);
    return function (dictShiftMap) {
        return q1(dictShiftMap)([  ]);
    };
};
var rp = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.rp);
    };
};
var rp$prime = function (dictMultiAlternative) {
    var rp1 = rp(dictMultiAlternative);
    return function (dictShiftMap) {
        return rp1(dictShiftMap)([  ]);
    };
};
var rt = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.rt);
    };
};
var rt$prime = function (dictMultiAlternative) {
    var rt1 = rt(dictMultiAlternative);
    return function (dictShiftMap) {
        return rt1(dictShiftMap)([  ]);
    };
};
var ruby = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.ruby);
    };
};
var ruby$prime = function (dictMultiAlternative) {
    var ruby1 = ruby(dictMultiAlternative);
    return function (dictShiftMap) {
        return ruby1(dictShiftMap)([  ]);
    };
};
var s = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.s);
    };
};
var s$prime = function (dictMultiAlternative) {
    var s1 = s(dictMultiAlternative);
    return function (dictShiftMap) {
        return s1(dictShiftMap)([  ]);
    };
};
var samp = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.samp);
    };
};
var samp$prime = function (dictMultiAlternative) {
    var samp1 = samp(dictMultiAlternative);
    return function (dictShiftMap) {
        return samp1(dictShiftMap)([  ]);
    };
};
var script = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.script);
    };
};
var script$prime = function (dictMultiAlternative) {
    var script1 = script(dictMultiAlternative);
    return function (dictShiftMap) {
        return script1(dictShiftMap)([  ]);
    };
};
var section = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.section);
    };
};
var section$prime = function (dictMultiAlternative) {
    var section1 = section(dictMultiAlternative);
    return function (dictShiftMap) {
        return section1(dictShiftMap)([  ]);
    };
};
var select = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.select);
    };
};
var select$prime = function (dictMultiAlternative) {
    var select1 = select(dictMultiAlternative);
    return function (dictShiftMap) {
        return select1(dictShiftMap)([  ]);
    };
};
var small = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.small);
    };
};
var small$prime = function (dictMultiAlternative) {
    var small1 = small(dictMultiAlternative);
    return function (dictShiftMap) {
        return small1(dictShiftMap)([  ]);
    };
};
var span = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.span);
    };
};
var span$prime = function (dictMultiAlternative) {
    var span1 = span(dictMultiAlternative);
    return function (dictShiftMap) {
        return span1(dictShiftMap)([  ]);
    };
};
var strong = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.strong);
    };
};
var strong$prime = function (dictMultiAlternative) {
    var strong1 = strong(dictMultiAlternative);
    return function (dictShiftMap) {
        return strong1(dictShiftMap)([  ]);
    };
};
var style = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.style);
    };
};
var style$prime = function (dictMultiAlternative) {
    var style1 = style(dictMultiAlternative);
    return function (dictShiftMap) {
        return style1(dictShiftMap)([  ]);
    };
};
var sub = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.sub);
    };
};
var sub$prime = function (dictMultiAlternative) {
    var sub1 = sub(dictMultiAlternative);
    return function (dictShiftMap) {
        return sub1(dictShiftMap)([  ]);
    };
};
var summary = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.summary);
    };
};
var summary$prime = function (dictMultiAlternative) {
    var summary1 = summary(dictMultiAlternative);
    return function (dictShiftMap) {
        return summary1(dictShiftMap)([  ]);
    };
};
var sup = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.sup);
    };
};
var sup$prime = function (dictMultiAlternative) {
    var sup1 = sup(dictMultiAlternative);
    return function (dictShiftMap) {
        return sup1(dictShiftMap)([  ]);
    };
};
var table = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.table);
    };
};
var table$prime = function (dictMultiAlternative) {
    var table1 = table(dictMultiAlternative);
    return function (dictShiftMap) {
        return table1(dictShiftMap)([  ]);
    };
};
var tbody = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.tbody);
    };
};
var tbody$prime = function (dictMultiAlternative) {
    var tbody1 = tbody(dictMultiAlternative);
    return function (dictShiftMap) {
        return tbody1(dictShiftMap)([  ]);
    };
};
var td = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.td);
    };
};
var td$prime = function (dictMultiAlternative) {
    var td1 = td(dictMultiAlternative);
    return function (dictShiftMap) {
        return td1(dictShiftMap)([  ]);
    };
};
var textarea = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.textarea);
    };
};
var textarea$prime = function (dictMultiAlternative) {
    var textarea1 = textarea(dictMultiAlternative);
    return function (dictShiftMap) {
        return textarea1(dictShiftMap)([  ]);
    };
};
var tfoot = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.tfoot);
    };
};
var tfoot$prime = function (dictMultiAlternative) {
    var tfoot1 = tfoot(dictMultiAlternative);
    return function (dictShiftMap) {
        return tfoot1(dictShiftMap)([  ]);
    };
};
var th = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.th);
    };
};
var th$prime = function (dictMultiAlternative) {
    var th1 = th(dictMultiAlternative);
    return function (dictShiftMap) {
        return th1(dictShiftMap)([  ]);
    };
};
var thead = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.thead);
    };
};
var thead$prime = function (dictMultiAlternative) {
    var thead1 = thead(dictMultiAlternative);
    return function (dictShiftMap) {
        return thead1(dictShiftMap)([  ]);
    };
};
var time = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.time);
    };
};
var time$prime = function (dictMultiAlternative) {
    var time1 = time(dictMultiAlternative);
    return function (dictShiftMap) {
        return time1(dictShiftMap)([  ]);
    };
};
var title = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.title);
    };
};
var title$prime = function (dictMultiAlternative) {
    var title1 = title(dictMultiAlternative);
    return function (dictShiftMap) {
        return title1(dictShiftMap)([  ]);
    };
};
var tr = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.tr);
    };
};
var tr$prime = function (dictMultiAlternative) {
    var tr1 = tr(dictMultiAlternative);
    return function (dictShiftMap) {
        return tr1(dictShiftMap)([  ]);
    };
};
var u = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.u);
    };
};
var u$prime = function (dictMultiAlternative) {
    var u1 = u(dictMultiAlternative);
    return function (dictShiftMap) {
        return u1(dictShiftMap)([  ]);
    };
};
var ul = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.ul);
    };
};
var ul$prime = function (dictMultiAlternative) {
    var ul1 = ul(dictMultiAlternative);
    return function (dictShiftMap) {
        return ul1(dictShiftMap)([  ]);
    };
};
var $$var = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM["var"]);
    };
};
var var$prime = function (dictMultiAlternative) {
    var var1 = $$var(dictMultiAlternative);
    return function (dictShiftMap) {
        return var1(dictShiftMap)([  ]);
    };
};
var video = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.video);
    };
};
var video$prime = function (dictMultiAlternative) {
    var video1 = video(dictMultiAlternative);
    return function (dictShiftMap) {
        return video1(dictShiftMap)([  ]);
    };
};
var el = function (dictShiftMap) {
    var el1 = Concur_Core_DOM.el(dictShiftMap)(Data_Functor.functorArray);
    return function (f) {
        return el1(viewAdapter(f));
    };
};
var em_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.em);
};
var fieldset_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.fieldset);
};
var figcaption_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.figcaption);
};
var figure_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.figure);
};
var footer_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.footer);
};
var form_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.form);
};
var h1_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.h1);
};
var h2_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.h2);
};
var h3_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.h3);
};
var h4_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.h4);
};
var h5_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.h5);
};
var h6_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.h6);
};
var head_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.head);
};
var header_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.header);
};
var html_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.html);
};
var i_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.i);
};
var iframe_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.iframe);
};
var ins_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.ins);
};
var kbd_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.kbd);
};
var label_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.label);
};
var legend_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.legend);
};
var li_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.li);
};
var main_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.main);
};
var mark_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.mark);
};
var menu_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.menu);
};
var meter_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.meter);
};
var nav_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.nav);
};
var noscript_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.noscript);
};
var object_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.object);
};
var ol_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.ol);
};
var optgroup_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.optgroup);
};
var option_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.option);
};
var output_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.output);
};
var p_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.p);
};
var picture_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.picture);
};
var pre_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.pre);
};
var progress_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.progress);
};
var q_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.q);
};
var rp_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.rp);
};
var rt_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.rt);
};
var ruby_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.ruby);
};
var s_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.s);
};
var samp_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.samp);
};
var script_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.script);
};
var section_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.section);
};
var select_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.select);
};
var small_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.small);
};
var span_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.span);
};
var strong_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.strong);
};
var style_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.style);
};
var sub_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.sub);
};
var summary_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.summary);
};
var sup_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.sup);
};
var table_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.table);
};
var tbody_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.tbody);
};
var td_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.td);
};
var textarea_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.textarea);
};
var tfoot_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.tfoot);
};
var th_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.th);
};
var thead_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.thead);
};
var time_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.time);
};
var title_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.title);
};
var tr_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.tr);
};
var u_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.u);
};
var ul_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.ul);
};
var var_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM["var"]);
};
var video_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.video);
};
var dt_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.dt);
};
var dt = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.dt);
    };
};
var dt$prime = function (dictMultiAlternative) {
    var dt1 = dt(dictMultiAlternative);
    return function (dictShiftMap) {
        return dt1(dictShiftMap)([  ]);
    };
};
var dl_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.dl);
};
var dl = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.dl);
    };
};
var dl$prime = function (dictMultiAlternative) {
    var dl1 = dl(dictMultiAlternative);
    return function (dictShiftMap) {
        return dl1(dictShiftMap)([  ]);
    };
};
var div_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.div);
};
var div = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.div);
    };
};
var div$prime = function (dictMultiAlternative) {
    var div1 = div(dictMultiAlternative);
    return function (dictShiftMap) {
        return div1(dictShiftMap)([  ]);
    };
};
var dialog_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.dialog);
};
var dialog = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.dialog);
    };
};
var dialog$prime = function (dictMultiAlternative) {
    var dialog1 = dialog(dictMultiAlternative);
    return function (dictShiftMap) {
        return dialog1(dictShiftMap)([  ]);
    };
};
var dfn_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.dfn);
};
var dfn = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.dfn);
    };
};
var dfn$prime = function (dictMultiAlternative) {
    var dfn1 = dfn(dictMultiAlternative);
    return function (dictShiftMap) {
        return dfn1(dictShiftMap)([  ]);
    };
};
var details_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.details);
};
var details = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.details);
    };
};
var details$prime = function (dictMultiAlternative) {
    var details1 = details(dictMultiAlternative);
    return function (dictShiftMap) {
        return details1(dictShiftMap)([  ]);
    };
};
var del_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.del);
};
var del = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.del);
    };
};
var del$prime = function (dictMultiAlternative) {
    var del1 = del(dictMultiAlternative);
    return function (dictShiftMap) {
        return del1(dictShiftMap)([  ]);
    };
};
var dd_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.dd);
};
var dd = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.dd);
    };
};
var dd$prime = function (dictMultiAlternative) {
    var dd1 = dd(dictMultiAlternative);
    return function (dictShiftMap) {
        return dd1(dictShiftMap)([  ]);
    };
};
var datalist_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.datalist);
};
var datalist = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.datalist);
    };
};
var datalist$prime = function (dictMultiAlternative) {
    var datalist1 = datalist(dictMultiAlternative);
    return function (dictShiftMap) {
        return datalist1(dictShiftMap)([  ]);
    };
};
var colgroup_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.colgroup);
};
var colgroup = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.colgroup);
    };
};
var colgroup$prime = function (dictMultiAlternative) {
    var colgroup1 = colgroup(dictMultiAlternative);
    return function (dictShiftMap) {
        return colgroup1(dictShiftMap)([  ]);
    };
};
var col = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.col);
};
var col$prime = function (dictLiftWidget) {
    return col(dictLiftWidget)([  ]);
};
var code_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.code);
};
var code = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.code);
    };
};
var code$prime = function (dictMultiAlternative) {
    var code1 = code(dictMultiAlternative);
    return function (dictShiftMap) {
        return code1(dictShiftMap)([  ]);
    };
};
var cite_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.cite);
};
var cite = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.cite);
    };
};
var cite$prime = function (dictMultiAlternative) {
    var cite1 = cite(dictMultiAlternative);
    return function (dictShiftMap) {
        return cite1(dictShiftMap)([  ]);
    };
};
var caption_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.caption);
};
var caption = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.caption);
    };
};
var caption$prime = function (dictMultiAlternative) {
    var caption1 = caption(dictMultiAlternative);
    return function (dictShiftMap) {
        return caption1(dictShiftMap)([  ]);
    };
};
var canvas_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.canvas);
};
var canvas = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.canvas);
    };
};
var canvas$prime = function (dictMultiAlternative) {
    var canvas1 = canvas(dictMultiAlternative);
    return function (dictShiftMap) {
        return canvas1(dictShiftMap)([  ]);
    };
};
var button_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.button);
};
var button = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.button);
    };
};
var button$prime = function (dictMultiAlternative) {
    var button1 = button(dictMultiAlternative);
    return function (dictShiftMap) {
        return button1(dictShiftMap)([  ]);
    };
};
var br = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.br);
};
var br$prime = function (dictLiftWidget) {
    return br(dictLiftWidget)([  ]);
};
var body_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.body);
};
var body = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.body);
    };
};
var body$prime = function (dictMultiAlternative) {
    var body1 = body(dictMultiAlternative);
    return function (dictShiftMap) {
        return body1(dictShiftMap)([  ]);
    };
};
var blockquote_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.blockquote);
};
var blockquote = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.blockquote);
    };
};
var blockquote$prime = function (dictMultiAlternative) {
    var blockquote1 = blockquote(dictMultiAlternative);
    return function (dictShiftMap) {
        return blockquote1(dictShiftMap)([  ]);
    };
};
var big_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.big);
};
var big = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.big);
    };
};
var big$prime = function (dictMultiAlternative) {
    var big1 = big(dictMultiAlternative);
    return function (dictShiftMap) {
        return big1(dictShiftMap)([  ]);
    };
};
var bdo_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.bdo);
};
var bdo = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.bdo);
    };
};
var bdo$prime = function (dictMultiAlternative) {
    var bdo1 = bdo(dictMultiAlternative);
    return function (dictShiftMap) {
        return bdo1(dictShiftMap)([  ]);
    };
};
var bdi_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.bdi);
};
var bdi = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.bdi);
    };
};
var bdi$prime = function (dictMultiAlternative) {
    var bdi1 = bdi(dictMultiAlternative);
    return function (dictShiftMap) {
        return bdi1(dictShiftMap)([  ]);
    };
};
var base = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.base);
};
var base$prime = function (dictLiftWidget) {
    return base(dictLiftWidget)([  ]);
};
var b_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.b);
};
var b = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.b);
    };
};
var b$prime = function (dictMultiAlternative) {
    var b1 = b(dictMultiAlternative);
    return function (dictShiftMap) {
        return b1(dictShiftMap)([  ]);
    };
};
var audio_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.audio);
};
var audio = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.audio);
    };
};
var audio$prime = function (dictMultiAlternative) {
    var audio1 = audio(dictMultiAlternative);
    return function (dictShiftMap) {
        return audio1(dictShiftMap)([  ]);
    };
};
var aside_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.aside);
};
var aside = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.aside);
    };
};
var aside$prime = function (dictMultiAlternative) {
    var aside1 = aside(dictMultiAlternative);
    return function (dictShiftMap) {
        return aside1(dictShiftMap)([  ]);
    };
};
var article_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.article);
};
var article = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.article);
    };
};
var article$prime = function (dictMultiAlternative) {
    var article1 = article(dictMultiAlternative);
    return function (dictShiftMap) {
        return article1(dictShiftMap)([  ]);
    };
};
var area = function (dictLiftWidget) {
    return elLeaf(dictLiftWidget)(React_DOM.area);
};
var area$prime = function (dictLiftWidget) {
    return area(dictLiftWidget)([  ]);
};
var address_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.address);
};
var address = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.address);
    };
};
var address$prime = function (dictMultiAlternative) {
    var address1 = address(dictMultiAlternative);
    return function (dictShiftMap) {
        return address1(dictShiftMap)([  ]);
    };
};
var abbr_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.abbr);
};
var abbr = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.abbr);
    };
};
var abbr$prime = function (dictMultiAlternative) {
    var abbr1 = abbr(dictMultiAlternative);
    return function (dictShiftMap) {
        return abbr1(dictShiftMap)([  ]);
    };
};
var a_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.a);
};
var a = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.a);
    };
};
var a$prime = function (dictMultiAlternative) {
    var a1 = a(dictMultiAlternative);
    return function (dictShiftMap) {
        return a1(dictShiftMap)([  ]);
    };
};
var _map_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM.map);
};
var _map = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM.map);
    };
};
var _map$prime = function (dictMultiAlternative) {
    var _map1 = _map(dictMultiAlternative);
    return function (dictShiftMap) {
        return _map1(dictShiftMap)([  ]);
    };
};
var _data_ = function (dictShiftMap) {
    return el(dictShiftMap)(React_DOM["_data"]);
};
var _data = function (dictMultiAlternative) {
    return function (dictShiftMap) {
        return el$prime(dictShiftMap)(dictMultiAlternative)(React_DOM["_data"]);
    };
};
var _data$prime = function (dictMultiAlternative) {
    var _data1 = _data(dictMultiAlternative);
    return function (dictShiftMap) {
        return _data1(dictShiftMap)([  ]);
    };
};
export {
    viewAdapter,
    el,
    el$prime,
    elLeaf,
    text,
    $$int as int,
    number,
    a_,
    a,
    a$prime,
    abbr_,
    abbr,
    abbr$prime,
    address_,
    address,
    address$prime,
    area,
    area$prime,
    article_,
    article,
    article$prime,
    aside_,
    aside,
    aside$prime,
    audio_,
    audio,
    audio$prime,
    b_,
    b,
    b$prime,
    base,
    base$prime,
    bdi_,
    bdi,
    bdi$prime,
    bdo_,
    bdo,
    bdo$prime,
    big_,
    big,
    big$prime,
    blockquote_,
    blockquote,
    blockquote$prime,
    body_,
    body,
    body$prime,
    br,
    br$prime,
    button_,
    button,
    button$prime,
    canvas_,
    canvas,
    canvas$prime,
    caption_,
    caption,
    caption$prime,
    cite_,
    cite,
    cite$prime,
    code_,
    code,
    code$prime,
    col,
    col$prime,
    colgroup_,
    colgroup,
    colgroup$prime,
    _data_,
    _data,
    _data$prime,
    datalist_,
    datalist,
    datalist$prime,
    dd_,
    dd,
    dd$prime,
    del_,
    del,
    del$prime,
    details_,
    details,
    details$prime,
    dfn_,
    dfn,
    dfn$prime,
    dialog_,
    dialog,
    dialog$prime,
    div_,
    div,
    div$prime,
    dl_,
    dl,
    dl$prime,
    dt_,
    dt,
    dt$prime,
    em_,
    em,
    em$prime,
    embed,
    embed$prime,
    fieldset_,
    fieldset,
    fieldset$prime,
    figcaption_,
    figcaption,
    figcaption$prime,
    figure_,
    figure,
    figure$prime,
    footer_,
    footer,
    footer$prime,
    form_,
    form,
    form$prime,
    h1_,
    h1,
    h1$prime,
    h2_,
    h2,
    h2$prime,
    h3_,
    h3,
    h3$prime,
    h4_,
    h4,
    h4$prime,
    h5_,
    h5,
    h5$prime,
    h6_,
    h6,
    h6$prime,
    head_,
    head,
    head$prime,
    header_,
    header,
    header$prime,
    hr,
    hr$prime,
    html_,
    html,
    html$prime,
    i_,
    i,
    i$prime,
    iframe_,
    iframe,
    iframe$prime,
    img,
    img$prime,
    input,
    input$prime,
    ins_,
    ins,
    ins$prime,
    kbd_,
    kbd,
    kbd$prime,
    keygen,
    keygen$prime,
    label_,
    label,
    label$prime,
    legend_,
    legend,
    legend$prime,
    li_,
    li,
    li$prime,
    link,
    link$prime,
    main_,
    main,
    main$prime,
    _map_,
    _map,
    _map$prime,
    mark_,
    mark,
    mark$prime,
    menu_,
    menu,
    menu$prime,
    menuitem,
    menuitem$prime,
    meta,
    meta$prime,
    meter_,
    meter,
    meter$prime,
    nav_,
    nav,
    nav$prime,
    noscript_,
    noscript,
    noscript$prime,
    object_,
    object,
    object$prime,
    ol_,
    ol,
    ol$prime,
    optgroup_,
    optgroup,
    optgroup$prime,
    option_,
    option,
    option$prime,
    output_,
    output,
    output$prime,
    p_,
    p,
    p$prime,
    param,
    param$prime,
    picture_,
    picture,
    picture$prime,
    pre_,
    pre,
    pre$prime,
    progress_,
    progress,
    progress$prime,
    q_,
    q,
    q$prime,
    rp_,
    rp,
    rp$prime,
    rt_,
    rt,
    rt$prime,
    ruby_,
    ruby,
    ruby$prime,
    s_,
    s,
    s$prime,
    samp_,
    samp,
    samp$prime,
    script_,
    script,
    script$prime,
    section_,
    section,
    section$prime,
    select_,
    select,
    select$prime,
    small_,
    small,
    small$prime,
    source,
    source$prime,
    span_,
    span,
    span$prime,
    strong_,
    strong,
    strong$prime,
    style_,
    style,
    style$prime,
    sub_,
    sub,
    sub$prime,
    summary_,
    summary,
    summary$prime,
    sup_,
    sup,
    sup$prime,
    table_,
    table,
    table$prime,
    tbody_,
    tbody,
    tbody$prime,
    td_,
    td,
    td$prime,
    textarea_,
    textarea,
    textarea$prime,
    tfoot_,
    tfoot,
    tfoot$prime,
    th_,
    th,
    th$prime,
    thead_,
    thead,
    thead$prime,
    time_,
    time,
    time$prime,
    title_,
    title,
    title$prime,
    tr_,
    tr,
    tr$prime,
    track,
    track$prime,
    u_,
    u,
    u$prime,
    ul_,
    ul,
    ul$prime,
    var_,
    $$var as var,
    var$prime,
    video_,
    video,
    video$prime,
    wbr,
    wbr$prime
};
