// Generated by purs version 0.15.8
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Monad_Free from "../Control.Monad.Free/index.js";
import * as Control_MultiAlternative from "../Control.MultiAlternative/index.js";
import * as Control_Parallel_Class from "../Control.Parallel.Class/index.js";
import * as Control_Plus from "../Control.Plus/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Array_NonEmpty_Internal from "../Data.Array.NonEmpty.Internal/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_FoldableWithIndex from "../Data.FoldableWithIndex/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Semigroup_Foldable from "../Data.Semigroup.Foldable/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_AVar from "../Effect.AVar/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_AVar from "../Effect.Aff.AVar/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Effect_Exception from "../Effect.Exception/index.js";
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var map1 = /* #__PURE__ */ Data_Functor.map(Effect_Aff.functorAff);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var map2 = /* #__PURE__ */ Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray);
var bind = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var sequential = /* #__PURE__ */ Control_Parallel_Class.sequential(Effect_Aff.parallelAff);
var foldlWithIndex = /* #__PURE__ */ Data_FoldableWithIndex.foldlWithIndex(Data_Array_NonEmpty_Internal.foldableWithIndexNonEmptyArray);
var alt = /* #__PURE__ */ Control_Alt.alt(Effect_Aff.altParAff);
var parallel = /* #__PURE__ */ Control_Parallel_Class.parallel(Effect_Aff.parallelAff);
var empty = /* #__PURE__ */ Control_Plus.empty(Effect_Aff.plusParAff);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Effect_Aff.applicativeAff);
var foldMap1 = /* #__PURE__ */ Data_Semigroup_Foldable.foldMap1(Data_Array_NonEmpty_Internal.foldable1NonEmptyArray);
var pure2 = /* #__PURE__ */ Control_Applicative.pure(Control_Monad_Free.freeApplicative);
var foldrWithIndex = /* #__PURE__ */ Data_FoldableWithIndex.foldrWithIndex(Data_FoldableWithIndex.foldableWithIndexArray);
var show = /* #__PURE__ */ Data_Show.show(Effect_Exception.showError);
var $$void = /* #__PURE__ */ Data_Functor["void"](Effect.functorEffect);
var WidgetStepEff = /* #__PURE__ */ (function () {
    function WidgetStepEff(value0) {
        this.value0 = value0;
    };
    WidgetStepEff.create = function (value0) {
        return new WidgetStepEff(value0);
    };
    return WidgetStepEff;
})();
var WidgetStepView = /* #__PURE__ */ (function () {
    function WidgetStepView(value0) {
        this.value0 = value0;
    };
    WidgetStepView.create = function (value0) {
        return new WidgetStepView(value0);
    };
    return WidgetStepView;
})();
var Widget = function (x) {
    return x;
};
var widgetShiftMap = {
    shiftMap: function (f) {
        return f(identity);
    }
};
var widgetMonadRec = Control_Monad_Free.freeMonadRec;
var widgetFunctor = Control_Monad_Free.freeFunctor;
var map3 = /* #__PURE__ */ Data_Functor.map(widgetFunctor);
var widgetBind = Control_Monad_Free.freeBind;
var bind2 = /* #__PURE__ */ Control_Bind.bind(widgetBind);
var widgetApply = Control_Monad_Free.freeApply;
var widgetApplicative = Control_Monad_Free.freeApplicative;
var pure3 = /* #__PURE__ */ Control_Applicative.pure(widgetApplicative);
var widgetMonad = {
    Applicative0: function () {
        return widgetApplicative;
    },
    Bind1: function () {
        return widgetBind;
    }
};
var unWidget = function (v) {
    return v;
};
var mapViewStep = function (v) {
    return function (v1) {
        if (v1 instanceof WidgetStepEff) {
            return new WidgetStepEff(v1.value0);
        };
        if (v1 instanceof WidgetStepView) {
            return new WidgetStepView({
                view: v(v1.value0.view),
                cont: v1.value0.cont
            });
        };
        throw new Error("Failed pattern match at Concur.Core.Types (line 199, column 1 - line 199, column 80): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var mapView = function (f) {
    return function (v) {
        return Control_Monad_Free.hoistFree(mapViewStep(f))(v);
    };
};
var functorWidgetStep = {
    map: function (v) {
        return function (v1) {
            if (v1 instanceof WidgetStepEff) {
                return new WidgetStepEff(map(v)(v1.value0));
            };
            if (v1 instanceof WidgetStepView) {
                return new WidgetStepView({
                    view: v1.value0.view,
                    cont: map1(v)(v1.value0.cont)
                });
            };
            throw new Error("Failed pattern match at Concur.Core.Types (line 43, column 1 - line 45, column 72): " + [ v.constructor.name, v1.constructor.name ]);
        };
    }
};
var resume = /* #__PURE__ */ Control_Monad_Free.resume(functorWidgetStep);
var flipEither = function (v) {
    if (v instanceof Data_Either.Left) {
        return new Data_Either.Right(v.value0);
    };
    if (v instanceof Data_Either.Right) {
        return new Data_Either.Left(v.value0);
    };
    throw new Error("Failed pattern match at Concur.Core.Types (line 72, column 1 - line 75, column 13): " + [ v.constructor.name ]);
};
var effAction = function ($122) {
    return Widget(Control_Monad_Free.liftF(WidgetStepEff.create($122)));
};
var pulse = function (dictMonoid) {
    return effAction(pure(Data_Unit.unit));
};
var widgetMonadEff = function (dictMonoid) {
    return {
        liftEffect: effAction,
        Monad0: function () {
            return widgetMonad;
        }
    };
};
var displayStep = function (v) {
    return new WidgetStepView({
        view: v,
        cont: Effect_Aff.never
    });
};
var display = function (v) {
    return Control_Monad_Free.liftF(displayStep(v));
};
var widgetSemigroup = function (dictMonoid) {
    return {
        append: function (w1) {
            return function (w2) {
                return Control_MultiAlternative.orr(widgetMultiAlternative(dictMonoid))([ w1, w2 ]);
            };
        }
    };
};
var widgetPlus = function (dictMonoid) {
    return {
        empty: display(Data_Monoid.mempty(dictMonoid)),
        Alt0: function () {
            return widgetAlt(dictMonoid);
        }
    };
};
var widgetMultiAlternative = function (dictMonoid) {
    return {
        orr: function (wss) {
            var merge = function (dictMonoid1) {
                return function (ws) {
                    return function (wscs) {
                        var wsm = map2(function ($123) {
                            return Control_Monad_Free.wrap(WidgetStepView.create($123));
                        })(ws);
                        return bind(sequential(foldlWithIndex(function (i) {
                            return function (r) {
                                return function (w) {
                                    return alt(parallel(map1(Data_Tuple.Tuple.create(i))(w)))(r);
                                };
                            };
                        })(empty)(wscs)))(function (v) {
                            return pure1(combine(dictMonoid1)(Data_Maybe.fromMaybe(wsm)(Data_Array_NonEmpty.updateAt(v.value0)(v.value1)(wsm))));
                        });
                    };
                };
            };
            var combineViewsConts = function (dictMonoid1) {
                var foldMap11 = foldMap1(dictMonoid1.Semigroup0());
                return function (ws) {
                    return Control_Monad_Free.wrap(new WidgetStepView({
                        view: foldMap11(function (v) {
                            return v.view;
                        })(ws),
                        cont: merge(dictMonoid1)(ws)(map2(function (v) {
                            return v.cont;
                        })(ws))
                    }));
                };
            };
            var combineInner1 = function (dictMonoid1) {
                return function (ws) {
                    return function (freeNarr) {
                        var x = Data_Array_NonEmpty.uncons(freeNarr);
                        var v = resume(x.head);
                        if (v instanceof Data_Either.Right) {
                            return pure2(v.value0);
                        };
                        if (v instanceof Data_Either.Left && v.value0 instanceof WidgetStepEff) {
                            return Control_Monad_Free.wrap(new WidgetStepEff(function __do() {
                                var w = v.value0.value0();
                                return combineInner1(dictMonoid1)(ws)(Data_Array_NonEmpty["cons$prime"](w)(x.tail));
                            }));
                        };
                        if (v instanceof Data_Either.Left && v.value0 instanceof WidgetStepView) {
                            return combineInner(dictMonoid1)(Data_Array_NonEmpty.snoc(ws)(v.value0.value0))(x.tail);
                        };
                        throw new Error("Failed pattern match at Concur.Core.Types (line 130, column 10 - line 135, column 75): " + [ v.constructor.name ]);
                    };
                };
            };
            var combineInner = function (dictMonoid1) {
                return function (ws) {
                    return function (freeArr) {
                        var v = Data_Array_NonEmpty.fromArray(freeArr);
                        if (v instanceof Data_Maybe.Nothing) {
                            return combineViewsConts(dictMonoid1)(ws);
                        };
                        if (v instanceof Data_Maybe.Just) {
                            return combineInner1(dictMonoid1)(ws)(v.value0);
                        };
                        throw new Error("Failed pattern match at Concur.Core.Types (line 107, column 31 - line 110, column 49): " + [ v.constructor.name ]);
                    };
                };
            };
            var combine = function (dictMonoid1) {
                return function (wfs) {
                    var x = Data_Array_NonEmpty.uncons(wfs);
                    var v = resume(x.head);
                    if (v instanceof Data_Either.Right) {
                        return pure2(v.value0);
                    };
                    if (v instanceof Data_Either.Left && v.value0 instanceof WidgetStepEff) {
                        return Control_Monad_Free.wrap(new WidgetStepEff(function __do() {
                            var w = v.value0.value0();
                            return combine(dictMonoid1)(Data_Array_NonEmpty["cons$prime"](w)(x.tail));
                        }));
                    };
                    if (v instanceof Data_Either.Left && v.value0 instanceof WidgetStepView) {
                        return combineInner(dictMonoid1)(Data_Array_NonEmpty.singleton(v.value0.value0))(x.tail);
                    };
                    throw new Error("Failed pattern match at Concur.Core.Types (line 94, column 10 - line 99, column 77): " + [ v.constructor.name ]);
                };
            };
            var v = Data_Array_NonEmpty.fromArray(wss);
            if (v instanceof Data_Maybe.Just) {
                return combine(dictMonoid)(map2(unWidget)(v.value0));
            };
            if (v instanceof Data_Maybe.Nothing) {
                return Control_Plus.empty(widgetPlus(dictMonoid));
            };
            throw new Error("Failed pattern match at Concur.Core.Types (line 83, column 13 - line 85, column 21): " + [ v.constructor.name ]);
        },
        Plus0: function () {
            return widgetPlus(dictMonoid);
        }
    };
};
var widgetAlt = function (dictMonoid) {
    return {
        alt: Data_Semigroup.append(widgetSemigroup(dictMonoid)),
        Functor0: function () {
            return widgetFunctor;
        }
    };
};
var widgetAlternative = function (dictMonoid) {
    var widgetPlus1 = widgetPlus(dictMonoid);
    return {
        Applicative0: function () {
            return widgetApplicative;
        },
        Plus1: function () {
            return widgetPlus1;
        }
    };
};
var widgetMonoid = function (dictMonoid) {
    var widgetSemigroup1 = widgetSemigroup(dictMonoid);
    return {
        mempty: Control_Plus.empty(widgetPlus(dictMonoid)),
        Semigroup0: function () {
            return widgetSemigroup1;
        }
    };
};
var andd = function (dictMonoid) {
    var alt1 = Control_Alt.alt(widgetAlt(dictMonoid));
    var empty1 = Control_Plus.empty(widgetPlus(dictMonoid));
    return function (ws) {
        return bind2(foldrWithIndex(function (i) {
            return function (w) {
                return function (r) {
                    return alt1(map3(Data_Tuple.Tuple.create(i))(w))(r);
                };
            };
        })(empty1)(ws))(function (v) {
            var ws$prime = Data_Maybe.fromMaybe(ws)(Data_Array.deleteAt(v.value0)(ws));
            var $113 = Data_Array.length(ws$prime) <= 0;
            if ($113) {
                return pure3([ v.value1 ]);
            };
            return bind2(andd(dictMonoid)(ws$prime))(function (rest) {
                return pure3(Data_Maybe.fromMaybe([  ])(Data_Array.insertAt(v.value0)(v.value1)(rest)));
            });
        });
    };
};
var affAction = function (v) {
    return function (aff) {
        var handler = function (v1) {
            return function (v2) {
                if (v2 instanceof Data_Either.Left) {
                    return Effect_Console.log("Aff failed - " + show(v2.value0));
                };
                if (v2 instanceof Data_Either.Right) {
                    return $$void(Effect_AVar.tryPut(v2.value0)(v1));
                };
                throw new Error("Failed pattern match at Concur.Core.Types (line 229, column 3 - line 229, column 55): " + [ v1.constructor.name, v2.constructor.name ]);
            };
        };
        return Control_Monad_Free.wrap(new WidgetStepEff(function __do() {
            var $$var = Effect_AVar.empty();
            Effect_Aff.runAff_(handler($$var))(aff)();
            var ma = Effect_AVar.tryTake($$var)();
            if (ma instanceof Data_Maybe.Just) {
                return pure2(ma.value0);
            };
            if (ma instanceof Data_Maybe.Nothing) {
                return Control_Monad_Free.liftF(new WidgetStepView({
                    view: v,
                    cont: Effect_Aff_AVar.take($$var)
                }));
            };
            throw new Error("Failed pattern match at Concur.Core.Types (line 224, column 8 - line 226, column 71): " + [ ma.constructor.name ]);
        }));
    };
};
var asyncAction = function (v) {
    return function (handler) {
        return affAction(v)(Effect_Aff.makeAff((function () {
            var $124 = map(Effect_Aff.effectCanceler);
            return function ($125) {
                return $124(handler($125));
            };
        })()));
    };
};
var widgetMonadAff = function (dictMonoid) {
    var widgetMonadEff1 = widgetMonadEff(dictMonoid);
    return {
        liftAff: affAction(Data_Monoid.mempty(dictMonoid)),
        MonadEffect0: function () {
            return widgetMonadEff1;
        }
    };
};
export {
    WidgetStepEff,
    WidgetStepView,
    displayStep,
    Widget,
    unWidget,
    flipEither,
    andd,
    pulse,
    mapView,
    mapViewStep,
    display,
    effAction,
    affAction,
    asyncAction,
    functorWidgetStep,
    widgetFunctor,
    widgetBind,
    widgetApplicative,
    widgetApply,
    widgetMonad,
    widgetMonadRec,
    widgetShiftMap,
    widgetMultiAlternative,
    widgetSemigroup,
    widgetMonoid,
    widgetAlt,
    widgetPlus,
    widgetAlternative,
    widgetMonadEff,
    widgetMonadAff
};
