// Generated by purs version 0.15.8
import * as $foreign from "./foreign.js";

export {
    hash,
    setHash,
    host,
    setHost,
    hostname,
    setHostname,
    href,
    setHref,
    origin,
    setOrigin,
    pathname,
    setPathname,
    port,
    setPort,
    protocol,
    setProtocol,
    search,
    setSearch,
    assign,
    replace,
    reload
} from "./foreign.js";
